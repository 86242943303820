//import {   useRef } from "react";
import {Link} from 'react-router-dom';
import Homediv from "./Homediv";
import { Amplify } from 'aws-amplify';
import {  useState, useEffect } from "react";
//import { Authenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
Amplify.configure(awsExports);

export default function Home (){

  const [demoData, setDemos] = useState(null);     
  
    function fetchdata()
    {
       // console.log("from fetchdata"); 
        fetch("https://n1rkd77m64.execute-api.us-west-1.amazonaws.com/featured/1")
             .then(response => {
                 return response.json();
                  })
             .then(data => {
                  setDemos(data);
                 // console.log(data);
                  })
            .catch(err => {
                console.log(err);
            });
            
        
    }
    
    
  useEffect(() => {
        fetchdata();
        
    },[]);
 

return (
    <>
      
     <div className="bg-dark text-light" >
        <div className="container">
          <div className="row">
            <div className="col-10 pt-3 pb-3" >
                <h1 className="font-weight-bold pt-3 filler" >Welcome to the DATA Demos Library</h1>
                <p className="col pl-0 filler">Unleashing the Power of Data Through Demos. The Demos Videos Presented on This Website Showcase the Art of the Possible And Are Not Intended for Production Use.</p>
   
            </div>
            
        </div>
 
      </div>
    </div>
      {/* Container Top */}
           <div className="container pt-5">

            <div className="row">
                <div className="col">
                    <h1 className="font-weight-bold">Featured</h1>
                    <p className="filler">Explore these exciting featured demos that have been curated by industry experts. 
                    </p>
                </div>
            </div>
                   {/* Container Data Starts here */}
        
            <div className="row">
  
                  
        
                {/* Right side Card*/}
                <div className="col-12">
                    <div className="container-fluid">
                    
   
                    {/* Card Deck*/}
                     <div className="row" id="card-deck">
                     {/* Right Card*/}
                    
                         {demoData && <Homediv results={demoData} /> }
                    
                    </div>
                  
                    </div>  {/* className="container-fluid"*/}
                 
            </div>  {/* className="col-12"*/}
         </div>
      
        </div>
        <div className="card-header bg-white  " align='center'>   
             <p> <Link className="btn btn-primary" role="button" to="/List_Demos">Explore More Demos...</Link></p> 
        </div> 
        
        <div className="card-header bg-white  " >  </div>{/* For spacing b/w list and footer "*/}
        
     
     
    </>
    );
}

 