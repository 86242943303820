import './Demo_Intro';
import parse from 'html-react-parser';
import Fetchurl from '../Components/Fetchurl';
import config from "../Components/Configurations.js";

const Demo_div = ({ results }) => {
   // console.log('inside div');
   //  console.log(results.demo_category);
  return (
     <div className="container-fluid px-0">
        {/*<!-- SECONDARY NAVBAR CODE --> 
                    <nav className="navbar navbar-expand-sm navbar-light bg-white p-0">
                        <div className="navbar-nav">
                            <ul className="nav navbar-nav " id="secondary-menu">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#overview-anchor" >Overview</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#architecture-anchor" >Architecture</a>
                                </li>
                          
                            </ul>
                        </div>
                    </nav> */}
       { /* Header goes here */ }
        <div className="container-fluid px-0" align='left'>
            <div className="row mx-0">
                <div className="col-12 bg-dark text-light p-5 shadow-sm full-width-header-intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-6" id="header-parent">
                                <template id="header-template"></template>
                                <h2 id="header-title">{results.demo_title}</h2>
                                <p id="header-subtitle">{results.demo_overview}</p>
                                <p ><a id="header-demourl" className="btn btn-primary" role="button" href={config.cloudfront_url+'/'+results.demo_cfn_url} target="”_blank”">Deployment Stack</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a id="header-demourl" className="btn btn-primary" role="button" href={config.cloudfront_url+'/'+results.demo_ppt_url} target="”_blank”">Presentation</a></p>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

         {/* Overview start */}
        <div className="container pt-5 pb-5" align='left'>
        
            <div className="row">
                <div className="col-12">
                    <h2 id="overview-anchor">Overview</h2>
                </div>
            </div>
            <div className="row">
              <div className="col-12" id="overview-description-parent">
                 <template id="overview-description-template"></template>
                 <p id="header-subtitle">{parse(results.demo_desc)}</p>
                </div>
            </div>

           {/*!-- DETAILS AND PLAYER */}
            <div className="row">
                <div className="col-7" id="overview-subpoints-parent">
                    <template id="overview-subpoints-template"></template>
               
                        
                        <p id="how-subpoints-paragraph">
                            <Fetchurl url={parse(config.cloudfront_url+'/'+results.demo_details)}  />
                        </p>
                </div>
                <div className="col-5" id="overview-video-parent">
                    <template id="overview-video-template"></template>
                        <div className="" id="overview-video-video">
                        <video src={config.cloudfront_url+'/'+results.demo_video_url}  width="400" height="200" controls  />
                    </div>
                 </div>
            </div>

 
            {/*--  ARCHITECTURE SECTION -->*/}
                    
            <div className="container-fluid bg-light mt-5 pt-5 pb-5">
                <div className="container" id="architecture-anchor" >
                    <div className="row">
                        <div className="col-12">
                            <h2>Architecture</h2>
                        </div>
                    </div>
                    <div id='architecture'>
                    <img  src={config.cloudfront_url+'/'+results.demo_img_url}   alt="architecture" width="600" height="400" />
                        
                    </div>
                    
                </div>
          
            </div>
                    
                    
               {/* 03.3 FOLLOWUP SECTION -*/}
            <div className="container mt-5 pt-2" id="followup-anchor">
                <div className="row">
                    <div className="col-12" id="followup-description-parent">
                        <h2>References</h2>
                        <template id="followup-description-template"></template>
 
                        </div>
                </div>
                <div className="row">
                    <div className="col-9">
                       
                            <template id="followup-links-template"></template>
                           
                        <Fetchurl url={config.cloudfront_url+'/'+results.demo_references}  />
                    </div>
           
                </div>
            
             </div>
             
             
               <div className="container mt-5 pt-2" id="followup-anchor">
                <div className="row">
                    <div className="col-12" id="followup-description-parent">
                        <h4>Demo Owner(s)</h4>
                        <template id="followup-description-template"></template>
 
                        </div>
                </div>
                <div className="row">
                    <div className="col-9">
                       
                            <template id="followup-links-template"></template>
                           
                       <p id="header-subtitle">{results.demo_owners}</p>
                    </div>
           
                </div>
            
             </div>
             
            </div>  

          
    </div>
  );
};

export default Demo_div;

