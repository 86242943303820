//import {   useRef } from "react";
import {Link} from 'react-router-dom';
import Homediv from "./Homediv";
import { Amplify } from 'aws-amplify';
import {  useState, useEffect } from "react";
//import { Authenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
Amplify.configure(awsExports);


export default function GenAI (){

const [demoData, setDemos] = useState(null);      

return (
    <>
      <div class="embed-responsive embed-responsive-16by9">
	<iframe class="embed-responsive-item" allowFullScreen src="https://d14iy0mp1t34fu.cloudfront.net/"   
allow="clipboard-write self https://d14iy0mp1t34fu.cloudfront.net/"></iframe>
	</div>
     
     
    </>
    );
}

 
