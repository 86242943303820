import {Link, BrowserRouter} from 'react-router-dom';
import Header_1 from './Header_1.css';
import header_css from './header_css.css';
import { Auth, Hub } from 'aws-amplify'
import { Nav, Navbar, Image } from 'react-bootstrap';

function Header(prop){
    
    // const handlelogOut = () =>{
        
    //     prop.logOut();
    // };
    
   const  signOut = () =>  {
        Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log(err));
    }
    
    return(
            
    		<div id="rodeo-navbar">	  	 
    		
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                  <a className="navbar-brand brand-icon" href="https://demolibrary.d3h28m8grb9f0i.amplifyapp.com"></a>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                  </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <ul className="nav navbar-nav ml-auto">
                        <li className="nav-item " id="index-html">
                            <Link className="nav-link " to='/Home'>Home </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/List_Demos'>Demo List </Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://sim.amazon.com/issues/create?template=450b80ca-5d4e-48ac-b6b8-8756d80bbfec" target="”_blank”">Add New Demo</a>
                        </li>
                     <li className="nav-item">
                            <Link className="nav-link" to="/GenAI-Assistant" >GenAI-Assistant</Link>
                        </li> 
                         <li className="nav-item">
                         <a className="nav-link" style={{cursor: "pointer"}}   onClick={signOut} >Sign out</a>
                        
                          
                        </li>
                    </ul>
                </div>
                </nav> 
      
            </div>
         
        );
    
}
export default Header;
