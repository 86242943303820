import {Route,Routes, BrowserRouter} from 'react-router-dom';
import Home from './Pages/Home';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Demo_List from './Pages/Demo_List';
import Demo_Intro from './Pages/Demo_Intro';
import Add_Demo from './Pages/Add_Demo';
import List_Demos from './Pages/List_Demos';
import Demo from './Pages/Demo';
import GenAI from './Pages/GenAI';

function App (prop)
{
    return (
      <div className="App">
          <BrowserRouter>
            <Header userid={prop.uid}/>
              <Routes>
                <Route index element={<Home />} />
                <Route path='/Home' element={<Home />} />
                <Route path='/Demo_List' element={<Demo_List />} />
                <Route path='/Demo_Intro' element={<Demo_Intro />} />
                <Route path='/Add_Demo' element={<Add_Demo />} />
                <Route path='/List_Demos' element={<List_Demos />} />
                <Route path='/Demo' element={<Demo />} />
	    	<Route path='/GenAI-Assistant' element={<GenAI />} />
              </Routes>
               <Footer />
            </BrowserRouter>
          </div>
          
    
    );
    
}
export default App;
