import './Demo_Intro';
//import {  useState, useEffect, useRef } from "react";
import parse from 'html-react-parser';
import Fetchurl from '../Components/Fetchurl';


const DemoIntroDiv = ({ results }) => {
    
 
  return (
    
     <div className="container-fluid px-0">
     
 
        {results?.map((result) => {
          return (
          <div >
        {/*<!-- SECONDARY NAVBAR CODE --> 
                    <nav className="navbar navbar-expand-sm navbar-light bg-white p-0">
                        <div className="navbar-nav">
                            <ul className="nav navbar-nav " id="secondary-menu">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#overview-anchor" >Overview</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#architecture-anchor" >Architecture</a>
                                </li>
                          
                            </ul>
                        </div>
                    </nav> */}
       { /* Header goes here */ }
        <div className="container-fluid px-0" align='left'>
            <div className="row mx-0">
                <div className="col-12 bg-dark text-light p-5 shadow-sm full-width-header-intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-6" id="header-parent">
                                <template id="header-template"></template>
                                <h2 id="header-title">{result._source.title}</h2>
                                <p id="header-subtitle">{result._source.description}</p>
                                <p ><a id="header-demourl" className="btn btn-primary" role="button" href={result._source.cfn_template} target="”_blank”">Deployment Stack</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a id="header-demourl" className="btn btn-primary" role="button" href={result._source.ppt_url} target="”_blank”">Presentation</a></p>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

         {/* Overview start */}
        <div className="container pt-5 pb-5" align='left'>
        
            <div className="row">
                <div className="col-12">
                    <h2 id="overview-anchor">Overview</h2>
                </div>
            </div>
            <div className="row">
              <div className="col-12" id="overview-description-parent">
                 <template id="overview-description-template"></template>
                 <p id="header-subtitle">{parse(result._source.overview)}</p>
                </div>
            </div>

           {/*!-- DETAILS AND PLAYER */}
            <div className="row">
                <div className="col-7" id="overview-subpoints-parent">
                    <template id="overview-subpoints-template"></template>
               
                        
                        <p id="how-subpoints-paragraph">
                            <Fetchurl url={result._source.how_it_works}  />
                        </p>
                </div>
                <div className="col-5" id="overview-video-parent">
                    <template id="overview-video-template"></template>
                        <div className="" id="overview-video-video">
                        <video src={result._source.video_url}  width="400" height="200" controls  />
                    </div>
                 </div>
            </div>

 
            {/*--  ARCHITECTURE SECTION -->*/}
                    
            <div className="container-fluid bg-light mt-5 pt-5 pb-5">
                <div className="container" id="architecture-anchor" >
                    <div className="row">
                        <div className="col-12">
                            <h2>Architecture</h2>
                        </div>
                    </div>
                    <div id='architecture'>
                    <img  src={result._source.img_url}   alt="architecture" width="600" height="400" />
                        
                    </div>
                    
                </div>
          
            </div>
                    
                    
               {/* 03.3 FOLLOWUP SECTION -*/}
            <div className="container mt-5 pt-2" id="followup-anchor">
                <div className="row">
                    <div className="col-12" id="followup-description-parent">
                        <h2>References</h2>
                        <template id="followup-description-template"></template>
 
                        </div>
                </div>
                <div className="row">
                    <div className="col-9">
                       
                            <template id="followup-links-template"></template>
                          <p id="overview-subpoints-paragraph">  {parse(result._source.more_info)} </p>
                        
                    </div>
                    <div className="col-4" id="followup-qr-parent">
                        <div id="followup-qr-image" className="row text-center"  >
                        <canvas width="256" height="256"  ></canvas>
                    </div>
                </div>
                </div>
            
             </div>
            </div>  

            </div>
          );
        })}
          
    </div>
  );
};

export default DemoIntroDiv;

