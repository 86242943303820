//import React from "react";
import './Demo_Intro';
import classes from '../Components/header_css.css'; 

import {Link} from 'react-router-dom';

const Demodiv = ({ results }) => {
  //console.log(results);
  return (
    
     < >

        {results?.map((result) => {
          return (
            <div className="col-md-4 col-6 p-0 m-0 search-card " key={result._source.id}>
                <div className="card card-sm shadow-sm m-1" >   
                    <div className="card-header bg-dark p-1" >  </div>
              
                   <div className="card-header bg-white">
                         <div className="row">
                            <div className="col m-0" id={result._source.category}><p className="text-black-50 small pt-0 pb-0 m-0 text-uppercase font-weight-bold  search-industries"   >{result._source.category}</p>
                            </div>
                        </div>
                       </div> 
                                   
                      <div className="card-body" key={result.title}>
                            <h5 className="card-title text-primary search-title">
                            <Link to='/Demo_intro'  state={{ from: {result} }}>{result._source.title}</Link></h5>
                            <h6 className="card-subtitle mb-2 text-muted ">{result._source.description}</h6>


                      </div>
                      <div className="card-footer bg-white small" id="labels">
                            <span  className="badge-pill badge-light search-labels" >{result._source.tags}</span>
                      </div>
                </div>  
            </div>  
            
          );
        })}
        
    </>
   
  );
};

export default Demodiv;

