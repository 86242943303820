import { useLocation } from 'react-router-dom';
//import parse from 'html-react-parser';
import {  useState, useEffect, useRef } from "react";
import DemoIntroDiv from './DemoIntroDiv';

export default function Demo_intro(params){
    const location = useLocation();
    const { from } = location.state;
    
    {/*.Section - Fetching demo details from Opensearch */}
    
    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
         };
    
    let [demoData, setDemos] = useState(null); 
    
    useEffect(() => {
         fetch("https://d3uu0ansplr9o8.cloudfront.net/demointro-api?q="+from.result._source.id)
             .then(response => {
                 return response.json();
                  })
             .then(data => {
                  setDemos(data.hits.hits);
                  
         });
    },[]);
    {/*.Fetching demo details from Opensearch */}
    
    return(
       
    < > 
      
        {/* Fetch more details from OpenSearch for this demo*/}
        {demoData && <DemoIntroDiv results={demoData} />}  
        
        
      
    </>
        );
    
}