//import Header from '../components/Header';
import {  useState, useEffect } from "react";
//import { useLocation } from 'react-router-dom';
import Demodiv from "./Demodiv";
import DemoList_LeftMenu from "../Components/DemoList_LeftMenu";
//import { Authenticator } from '@aws-amplify/ui-react';

export default function Demo_List() {

    const [demoData, setDemos] = useState(null); 
    const [filterdata,setfilterdata] =useState('all');
    
 
    // on first reder of this page, demoDATA is set by useEffect. Once demodata is set, filteredList take effect and provide fitered demo list. On first page load, 
    //it returns all records. Later as the filtering used, same function is called and filtered result provided based on services, cateogry, title and description.
    
    let filteredList =demoData && demoData.filter((demolist) =>{
       // console.log("from filteredList="); 
        if(filterdata === 'all')
        {
            return demolist;
        }
        else{
           console.log("demolist="+demolist._source.services.toLowerCase());
             console.log("filter="+filterdata.toLowerCase());
         //  console.log("lower="+filterdata.toLowerCase());
         // console.log("upper="+demolist._source.services.toLowerCase());
         // =To be fixed: even when we are using toLowerCase() function, looks like filter/search still comparing exact case.
      
            if (demolist._source.services.toLowerCase().includes(filterdata.toLowerCase()))
            {
                return demolist._source.services.includes(filterdata);
            }
            else if (demolist._source.category.toLowerCase().includes(filterdata.toLowerCase()))
            {
                return demolist._source.category.includes(filterdata);
            }
                   if (demolist._source.title.toLowerCase().includes(filterdata.toLowerCase()))
            {
                return demolist._source.title.includes(filterdata);
            }
            else if (demolist._source.description.toLowerCase().includes(filterdata.toLowerCase()))
            {
                return demolist._source.description.includes(filterdata);
            }
           
        }
        window.scrollTo(0, 0);
        
    });
    
    function gotop()
    {
        window.scrollTo({
              top: 0, 
              behavior: 'smooth'
              /* you can also use 'auto' behaviour
                 in place of 'smooth' */
            });
    }
    
    // when first time this page loads, useEffect() will fetch the results from database ( AWS OpenSearch) using fetchdata() function. 
    function fetchdata()
    {
       // console.log("from fetchdata"); 
        fetch("https://d12g7orksolf2g.cloudfront.net/get_demo_list")
             .then(response => {
                 return response.json();
                  })
             .then(data => {
                  setDemos(data.hits.hits);
         });
        
    }
    
    
    useEffect(() => {
    
    //scrolling to top when navigating from home page or select filtering.
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
        fetchdata();
        
    },[]);
 
    function onFilterValueSelected(filtervalue){
        
        // this function is used to filter the demo list and show the results based on input value.
        // when this function is called through featured list or category (radio buttons), they pass string value.
        // however, search bar returns a object. Hence here we are using 'typeof' function to track wheather function is called
        // from search bar or featured list. Accordingly we prepare the filtervalue to pass to state function.
 
        
        if(typeof(filtervalue) === 'string')
        {
            setfilterdata(filtervalue);
        }
        else
        {
            setfilterdata(filtervalue.target.value);
        }
        
    }
 
 
    return(
       
        <>  
         
         
            {/* Header */}
            <div className="container-fluid px-0">
                <div className="row mx-0">
                 <div className="col-12 bg-dark text-light shadow-sm full-width-header-landing">
                    <div className="container">
                      <div className="row">
                        <div className="col-10 pt-3 pb-3">
                            <h1 className="font-weight-bold pt-3 filler">DATA Demos Library</h1>
                        </div>
                    </div>
                </div>
                </div>
             </div>
            </div>
          {/* Container Top */}
           <div className="container pt-5">
                    <div className="row">
                        <div className="col">
                           {/* <h1 className="font-weight-bold">Demos</h1> */}
                            <p className="filler">Please use filter or search box to help select an interesting demo to explore. 
                            </p>
                        </div>
                    </div>
                   {/* Container Data Starts here */}
        
            <div className="row">
  
               <DemoList_LeftMenu filterValueSelected={onFilterValueSelected} />

                  
        
                {/* Right side Card*/}
                <div className="col-9">
                    <div className="container-fluid">
                    
                    {/* Search Box*/}
                    
                        <div className="row" >
                            <div className="col pl-1 pr-1">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                      <button className="btn btn-success" type="button">Search</button>
                                  </div>
                                     <input type="text" className="form-control" id="search-title"  onChange={onFilterValueSelected} />
                                     
                                </div> 
                            </div>
                           
                        </div>
                        
                    {/* Card Deck*/}
                     <div className="row" id="card-deck">
                     {/* Right Card*/}
                    
                      {demoData && <Demodiv results={filteredList} /> }
                    
                    </div>
                  
                    </div>  {/* className="container-fluid"*/}
                 
            </div>  {/* className="col-9"*/}
         </div>
        </div>
        <div className="card-header bg-white  " align='right'> <a style={{cursor:'pointer'}}  onClick={gotop}>Back To Top</a> </div> {/* For spacing b/w list and footer "*/}
        <div className="card-header bg-white  " >  </div>{/* For spacing b/w list and footer "*/}
        
        
      </>
    );
    
}