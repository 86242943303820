import { useLocation } from 'react-router-dom';
//import parse from 'html-react-parser';
import { useSearchParams } from "react-router-dom";
import {  useState, useEffect, useRef } from "react";
import DemoIntroDiv from './Demo_div';

export default function Demo(params){
    
    const [note, setNote] = useState('');
    
    const [demoData, setDemos] = useState(null); 
    const [searchParams] = useSearchParams();
    const demo_id = searchParams.get("id") ;
    
    function gotop()
    {
        window.scrollTo({
              top: 0, 
              behavior: 'smooth'
              /* you can also use 'auto' behaviour
                 in place of 'smooth' */
            });
    }
    useEffect(() => {
         fetch("https://n1rkd77m64.execute-api.us-west-1.amazonaws.com/items/"+demo_id)
             .then(response => { return response.json(); })
             .then(data => { setDemos(data)})
             .catch(err => {
                //alert(err);
                setNote(err);
              });
          
    },[]);
   
    return(
       
    <> 
     
        {demoData && <DemoIntroDiv results={demoData} />}  
        <div className="card-header bg-white  " align='right'> <a style={{cursor:'pointer'}}  onClick={gotop}>Back To Top</a> </div>
         <label>{note}</label>
        
      
    </>
        );
    
}