
export default function DemoList_LeftMenu(props){

    function onFilterValueChanged(event){
         // filterValueSelected will get value of calling radio button. This value will be passed to onFilterValueSelected function on Demo_List.jsx page.
        
        props.filterValueSelected(event.target.value);
         
       
    }
    return(
                     
                <div className="col-3">
                    <div className="container-fluid bg-white p-3">
                        <div className="row">
                            <div className="col">
                                <h5 className="font-weight-bold">Filter by:</h5>
                                 
                            </div>
                        </div>
                          
                        <div id="featured_service-parent">
                            <h6 className="font-weight-bold mt-3">Featured Service</h6>
                             
                                <div className="whole-thing">
                                    <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="all" id="all" onChange={onFilterValueChanged} defaultChecked  />
                                        <label className="form-check-featured_service"   id="dms"> All </label> 
                                    </div>
                                  <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="dms" id="dms" onChange={onFilterValueChanged}   />
                                        <label className="form-check-featured_service"   id="dms"> AWS Database Migration Service </label> 
                                    </div>
                                      <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="aurora postgres" id="aurora_postgres" onChange={onFilterValueChanged} />
                                        <label className="form-check-featured_service"   id="aurora_postgresql"> Amazon Aurora PostgreSQL </label> 
                                    </div>
                                    <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="aurora mysql" id="Aaurora_mysql" onChange={onFilterValueChanged} />
                                        <label className="form-check-featured_service"   id="aurora_mySQL"> Amazon Aurora MySQL </label> 
                                    </div>
                                    <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="ddb" id="ddb" onChange={onFilterValueChanged} />
                                        <label className="form-check-featured_service"   id="ddb"> Amazon DynamoDB </label> 
                                    </div>
                                     <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="rds postgres" id="rds postgres" onChange={onFilterValueChanged} />
                                        <label className="form-check-featured_service"   id="rds_postgresql"> RDS PostgreSQL </label> 
                                    </div>
                                     <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="rds mysql" id="rds mysql" onChange={onFilterValueChanged} />
                                        <label className="form-check-featured_service"   id="rds_mysql"> RDS MySQL </label> 
                                    </div>
                                     <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="rds oracle" id="rds oracle" onChange={onFilterValueChanged} />
                                        <label className="form-check-featured_service"   id="rds_oracle"> RDS Oracle </label> 
                                    </div>
                                     <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="rds sqlserver" id="rds sqlserver" onChange={onFilterValueChanged} />
                                        <label className="form-check-featured_service"   id="rds_sqlserver"> RDS SQL Server </label> 
                                    </div>
                                     <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="docdb" id="docdb" onChange={onFilterValueChanged} />
                                        <label className="form-check-featured_service"   id="docdb"> Amazon DocumentDB </label> 
                                    </div>
                                     <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="opensearch" id="opensearch" onChange={onFilterValueChanged} />
                                        <label className="form-check-featured_service"   id="docdb"> Amazon OpenSearch </label> 
                                    </div>
                                     <div className="form-check pt-1">
                                        <input className="form-check-input search-featured_service" name="service-radio" type="radio" value="redshift" id="redshift" onChange={onFilterValueChanged} />
                                        <label className="form-check-featured_service"   id="docdb"> Amazon Redshift </label> 
                                    </div>
                                </div>
                            
                        </div>
                        <div id="featured_service-parent">
                            <h6 className="font-weight-bold mt-3">Category</h6>
                            <div className="whole-thing">
                              <div className="form-check pt-1">
                                   <input className="form-check-input search-featured_service" name="category-radio" type="radio" value="all" id="all" defaultChecked onChange={onFilterValueChanged} />
                                  <label className="form-check-featured_service"   id="migration"> All </label> 
                              </div>
                              <div className="form-check pt-1">
                                   <input className="form-check-input search-featured_service" name="category-radio" type="radio" value="migration" id="MIGRATION" onChange={onFilterValueChanged}  />
                                  <label className="form-check-featured_service"   id="migration"> Migration </label> 
                              </div>
                               <div className="form-check pt-1">
                                     <input className="form-check-input search-featured_service" name="category-radio" type="radio" value="modernization" id="MODERNIZATION" onChange={onFilterValueChanged} />
                                     <label className="form-check-featured_service"   id="modernization"> Modernization </label> 
                              </div>
                              <div className="form-check pt-1">
                                   <input className="form-check-input search-featured_service" name="category-radio" type="radio" value="resiliency" id="RESILIENCY" onChange={onFilterValueChanged} />
                                    <label className="form-check-featured_service"   id="resiliency"> Resiliency </label> 
                              </div>
                              <div className="form-check pt-1">
                                   <input className="form-check-input search-featured_service" name="category-radio" type="radio" value="cost" id="COST" onChange={onFilterValueChanged} />
                                    <label className="form-check-featured_service"   id="cost"> Cost </label> 
                              </div>
                            </div>
                        </div>
                    </div>

                </div>   
        
        );
}