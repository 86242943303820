import { Auth } from 'aws-amplify';
import Footer from './Footer';


export default function Signup ()
{
return(
    
    <>
        <div className="bg-dark text-light">
        <div className="container">
          <div className="row">
            <div className="col-10 pt-3 pb-3">
                <h1 className="font-weight-bold pt-3 filler">Welcome to the DATA Demo Library</h1>
                <p className="col pl-0 filler">Unleashing the Power of Data Through Demos. The Demo Videos Presented on This Website Showcase the Art of the Possible And Are Not Intended for Production Use.</p>
   
            </div>
            
        </div>
        <div id="alerts" className="pl-1 row pt-1">
            <div className="alert alert-warning alert-dismissible fade show" role="alert">
               <p>  This tool is created and managed by AWS Solutions Architects and is not an official tool provided by AWS.  </p>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
              </div>
        </div>  
      </div>
         </div>
    <div className="card-header bg-white  " align='center'>  
     <p> <img src='prod_demoportal_QR_Code.png' />Scan the code to open website on your phone. 
 </p>
      <p><button onClick={() => Auth.federatedSignIn()} className="btn btn-primary" role="button">Sign In</button>  </p>
      
    </div>
    
    <Footer />
    
    </>
    ) ;   
}

