import { Auth } from 'aws-amplify';
import  Signup from './Signup';
import  App  from '../App';
import {  useState, useEffect } from "react";

export default function  Auth_parse () {
    
    const [user, setUser] = useState(null);

    const checkUser = () => {
        Auth.currentAuthenticatedUser()
        .then(user => {
            setUser(user.username); 
           
        })
        .catch(err => console.log(err));
    };
    


    useEffect(() => {
       
      checkUser();
       
    }, []);
   if (user )
        return (
     
        <div className='App'>
            <App />
        </div>
           );
    else 
    return (
        
        <div >
        <Signup />
        </div>
        
        );
  
}
 