
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import {Amplify} from 'aws-amplify';
import config from './aws-exports';
import Auth_parse from './Components/Auth_parse';


config.oauth.redirectSignIn = `${window.location.origin}/`;
config.oauth.redirectSignOut = `${window.location.origin}/`;

config.oauth.federatedTarget = undefined;

config.aws_project_region = "us-west-1";
config.aws_cognito_region = "us-west-1";
config.aws_user_pools_id = "us-west-1_WvMrZjSAN";
config.aws_user_pools_web_client_id = "3dk5rp9pd0g5ipqddurmd7v6nh";
//config.aws_cognito_identity_pool_id = "us-west-1:60de2a4b-68b1-42a7-b9c8-8837bdd09cc0";
config.oauth.scope = ["aws.cognito.signin.user.admin",
            "email",
            "openid"];
config.oauth.responseType =  "code";

Amplify.Logger.LOG_LEVEL = 'DEBUG';
Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <Auth_parse />
  
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();