
const config = {

"demo_admins": "ravimat@amazon.com",    
"cloudfront_url": "https://d24e08fbrqvgcz.cloudfront.net" ,
"s3_bucke_url":"prod-demoportal-data.s3.us-west-1.amazonaws.com",
"s3_bucket_name":"prod-demoportal-data",
"demo_ppt" : "demo_presentation.pptx",
"demo_details": "demo_details.html",
"demo_cfn": "demo_cfn.yaml",
"demo_video": "demo_video.mp4",
"demo_img": "architecture.png",
"demo_references":"demo_references.html"
};

export default config;