import classes from './header_css.css';
import Header_1 from './Header_1.css';
 
function Footer(){
    
    
    return(
         <header className="footer">
         
        <div className="logofooter"  >
           
        <nav className="navbar navbar-expand-sm navbar-dark" >
            <ul className="nav navbar-nav ml-auto small">
               <li className="nav-item">
                    <a className="nav-link active" href="#">Contact Us:</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link active" href="mailto:ravimat@amazon.com">Email</a>
                </li>
                    
                <li className="nav-item">
                     <a className="nav-link active" href="https://amzn-aws.slack.com/archives/C0613FGRAKB"> Slack</a>
                </li>
                <li><a className="nav-link active" href="#" align='right'>[ Best viewed in Mozilla Firefox ]</a></li>     
             </ul>
         </nav>
          
        </div>
        </header>
        );
}

export default Footer;

