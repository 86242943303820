//import React, { Component } from 'react';
import {  useState, useEffect } from "react";
import config from "../Components/Configurations.js";
import parse from 'html-react-parser';

export default function   Add_Demo() {

    const [demoid, setDemoid] = useState('');
    const [vurl, setVurl] = useState('');
    const [purl, setPurl] = useState('');
    const [curl, setCurl] = useState('');
    const [durl, setDurl] = useState('');
    const [iurl, setIurl] = useState('');
    const [rurl, setRurl] = useState('');
    const [note, setNote] = useState('');
    const [nextsteps, setNextsteps] = useState('');
    
    const handleSubmit = (event) =>  {
    
        event.preventDefault();
        if (event.target.elements.demoid.value.trim() == "")
        {
            alert("Demo ID can't be blank");
            event.target.elements.demoid.focus();
        }
        else if (event.target.elements.title.value.trim() == "")
        {
            alert("Demo title can't be blank");
            event.target.elements.title.focus();
        }
        else if(event.target.elements.overview.value.trim() == "")
        {
            alert("Demo overview can't be blank");
            event.target.elements.overview.focus();
        }
        else if(event.target.elements.desc.value.trim() == "")
        {
            alert("Demo description can't be blank");
            event.target.elements.desc.focus();
        }
        else if(event.target.elements.services.value.trim() == "")
        {
            alert("Demo services can't be blank");
            event.target.elements.services.focus();
        }
        else if(event.target.elements.category.value.trim() == "")
        {
            alert("Demo category can't be blank");
            event.target.elements.category.focus();
        }
        else if(event.target.elements.tag.value.trim() == "")
        {
            alert("Demo tags can't be blank");
            event.target.elements.tag.focus();
        }
        else if(event.target.elements.owners.value.trim() == "")
        {
            alert("Demo owners can't be blank");
            event.target.elements.owners.focus();
        }
        else if(event.target.elements.sim.value.trim() == "")
        {
            alert("Demo SIM ticket can't be blank");
            event.target.elements.sim.focus();
        }
        else if(event.target.elements.featured.value.trim() == "")
        {
            alert("Demo featured can't be blank");
            event.target.elements.featured.focus();
        }
        else if(event.target.elements.visible.value.trim() == "")
        {
            alert("Demo visibility can't be blank");
            event.target.elements.visible.focus();
        }
        else
        {
            const data = {"demo_id":event.target.elements.demoid.value.toLowerCase(),
                          "demo_title":event.target.elements.title.value,
                          "demo_overview": event.target.elements.overview.value,
                          "demo_desc":event.target.elements.desc.value,
                          "demo_services":event.target.elements.services.value.toLowerCase(),
                          "demo_category":event.target.elements.category.value.toLowerCase(),
                          "demo_tags":event.target.elements.tag.value.toLowerCase(),
                          "demo_featured":event.target.elements.featured.value,
                          "demo_video_url":event.target.elements.videourl.value.toLowerCase(),
                          "demo_ppt_url":event.target.elements.ppturl.value.toLowerCase(),
                          "demo_cfn_url":event.target.elements.cfnurl.value.toLowerCase(),
                          "demo_details":event.target.elements.details.value.toLowerCase(),
                          "demo_owners":event.target.elements.owners.value,
                          "demo_ticket":event.target.elements.sim.value,
                          "demo_visibility":event.target.elements.visible.value,
                          "demo_img_url":event.target.elements.img.value.toLowerCase(),
                          "demo_references":event.target.elements.references.value.toLowerCase(),
                          "demo_create_date":new Date().toLocaleString() 
                
            };
            
            let next_steps = "<br /> Run below commands to upload demo aritifacts to Amazon S3 bucket.[ Update command to reflect local file names] <br />";
            next_steps = next_steps + "<br />" + "aws s3 cp "+ config.demo_video + " s3://"+config.s3_bucket_name+"/"+event.target.elements.demoid.value.toLowerCase()+"/"+event.target.elements.demoid.value.toLowerCase()+'.mp4';
            next_steps = next_steps + "<br />" + "aws s3 cp "+ config.demo_ppt + " s3://"+config.s3_bucket_name+"/"+event.target.elements.demoid.value.toLowerCase()+"/"+event.target.elements.demoid.value.toLowerCase()+'.pptx';
            next_steps = next_steps + "<br />" + "aws s3 cp "+ config.demo_cfn + " s3://"+config.s3_bucket_name+"/"+event.target.elements.demoid.value.toLowerCase()+"/"+event.target.elements.demoid.value.toLowerCase()+'.yaml';
            next_steps = next_steps + "<br />" + "aws s3 cp "+ config.demo_details + " s3://"+config.s3_bucket_name+"/"+event.target.elements.demoid.value.toLowerCase()+"/"+config.demo_details;
            next_steps = next_steps + "<br />" + "aws s3 cp "+ config.demo_img + " s3://"+config.s3_bucket_name+"/"+event.target.elements.demoid.value.toLowerCase()+"/"+config.demo_img;
            next_steps = next_steps + "<br />" + "aws s3 cp "+ config.demo_references + " s3://"+config.s3_bucket_name+"/"+event.target.elements.demoid.value.toLowerCase()+"/"+config.demo_references;
            
            //alert(JSON.stringify(data));
                
                fetch('https://n1rkd77m64.execute-api.us-west-1.amazonaws.com/items', {
                    method: 'PUT',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
              .then(data =>  console.log(data))
              .then(setNote('Successfully Created New Demo Record!'))
              .then(setNextsteps(next_steps))
              .catch(err => {
                //alert(err);
                setNote(err);
              });
               event.target.reset();
        }
    };
    
   
    
    const inputDemoId = (e) => {
        
        // Here we are not prefixing the URLs with S3 endpoint or CloudFront endoint. This allows us to later change the endpoints or move data b/w S3 buckets 
        //without requiring to change anything on database (hard code URLs).
        // At the time of fetching these objects from S3, we will prefix these URLs by calling S3/CloudFront URL from Configurations.js file.
        // We arealso setting demo_id as prefix to these files so S3 folders will always be same name as the demo id/name.
        // Next step would be to upload these content on the path generated below.
        
        setDemoid(e.target.value);
        
        setVurl(e.target.value.toString() + '/'+e.target.value.toString()+'.mp4');
        setPurl(e.target.value.toString() + '/'+e.target.value.toString()+'.pptx');
        setCurl(e.target.value.toString() + '/'+e.target.value.toString()+'.yaml');
        setDurl(e.target.value.toString() + '/'+config.demo_details);
        setIurl(e.target.value.toString() + '/'+config.demo_img);
        setRurl(e.target.value.toString() + '/'+config.demo_references);

    
    };
 
    return (
            <>
                <div className="bg-dark text-light" >
                    <div className="container">
                        <div className="row">
                            <div className="col-10 pt-3 pb-3" >
                            <h1 className="font-weight-bold pt-3 filler" >Add New Demo</h1>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                </div>
                <div className="container pt-5">
                <div className="row">
                <div className="col pl-1 pr-1">
                <div className="container">
                    
                
                <form onSubmit={handleSubmit}>
                                                   
                    <label >Unique ID</label><input type="text" className="form-control" id="demoid" onChange={inputDemoId}  />
                    <label >Title</label><input type="text" className="form-control" id="title"   />
                    <label >Overview </label><input type="text" className="form-control" id="overview" placeholder="<p> </p>"   />
                    <label >Description</label><textarea className="form-control" id="desc"  placeholder="<p> </p>"  />
                    <label >AWS Services</label><input type="text" className="form-control" id="services"    />
                    <label >Category</label><input type="text" className="form-control" id="category" placeholder="Migration or Modernization etc."   />
                    <label >Tags</label><input type="text" className="form-control" id="tag"     />
                    <label >Featured</label><input type="text" className="form-control" id="featured" placeholder="1"   />
                    <label >Video URL</label><input type="text" className="form-control" id="videourl" value={vurl}  />
                    <label >PPT URL</label><input type="text" className="form-control" id="ppturl" value={purl}     />
                    <label >CFN URL</label><input type="text" className="form-control" id="cfnurl"  value={curl}   />
                    <label >Demo Details URL</label><input type="text" className="form-control" id="details" value={durl}    />
                    <label >Architecture/Image URL</label><input type="text" className="form-control" id="img" value={iurl}    />
                    <label >References URL</label><input type="text" className="form-control" id="references" value={rurl}    />
                    <label >Owners</label><input type="text" className="form-control" id="owners"    />
                    <label >SIM Ticket</label><input type="text" className="form-control" id="sim"    />
                    <label >Visibility</label><input type="text" className="form-control" id="visible" placeholder="0"  />
                    <br />
                    <button className="btn btn-primary" type="submit">Submit</button>
                    <br />
                    <label><font color='green'>{note} </font></label>
                    <br />
                    <label><font color='red'>{parse(nextsteps)} </font></label>
                </form>
                <br /><br /><br />
                
                    
                </div>
                </div>
                </div>
               </div> 
            </>
        );
    }