import axios from 'axios';
import parse from 'html-react-parser';
import { useState, useEffect } from 'react';

export default function Fetchurl (result){
    
 const [data, setData] = useState(null);

  console.log(result.url);
  
  
  useEffect(() => {
    axios.get(result.url)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

    
    return(
        <>
        {data && parse(data)}
        
        
        </>
        );
}